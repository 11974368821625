import Vue from 'vue';
import * as odinApi from '@/gen/api';
import {
  UserEditForm,
  UserSignupForm,
  OpappAirCard
} from '@/models/forms/UserForm';
import * as titanApi from '@/gen/titan/api';
import { IUserRepository } from './interface/IUserRepository';

export class UserRepository implements IUserRepository {
  private async currentUserApiClient() {
    const config = await Vue.prototype.$getConfigWithToken(Vue.prototype.$auth);
    return new odinApi.CurrentUserApi(config);
  }

  private async oneApiClient() {
    const config = await Vue.prototype.$getTitanConfigWithToken(
      Vue.prototype.$auth
    );
    return new titanApi.OneApi(config);
  }

  async getCurrentUser() {
    const client = await this.currentUserApiClient();
    const res = await client.getCurrentUser();
    return res.data;
  }

  async createUser(data: UserSignupForm, digitalOpFlg: boolean) {
    const requestBody: titanApi.ReqOneUserPost = {
      last_name: data.lastName,
      first_name: data.firstName,
      hira_last_name: data.hiraLastName,
      hira_first_name: data.hiraFirstName,
      zipcode1: data.zipcode1,
      zipcode2: data.zipcode2,
      pref: data.pref as titanApi.ReqOneUserPostPrefEnum,
      city: data.city,
      town: data.town,
      building: data.building,
      phone1_1: data.phone1_1,
      phone1_2: data.phone1_2,
      phone1_3: data.phone1_3,
      phone2_1: data.phone2_1,
      phone2_2: data.phone2_2,
      phone2_3: data.phone2_3,
      birth_year: data.birthYear,
      birth_month: data.birthMonth,
      birth_day: data.birthDay,
      gender_typ: data.genderTyp as titanApi.ReqOneUserPostGenderTypEnum,
      shokugyou_typ: data.shokugyouTyp as titanApi.ReqOneUserPostShokugyouTypEnum,
      promo_mail_from_one_receive_flg: data.promoMailFromOneReceiveFlg,
      promo_mail_from_op_card_receive_flg: data.promoMailFromOpCardReceiveFlg
    };
    // デジタルカードを発行しない場合は、小田急お客さま番号とセキュリティコードをリクエストボディに含める
    if (!digitalOpFlg) {
      requestBody.odakyu_customer_no =
        data.opCardNum1 + data.opCardNum2 + data.opCardNum3;
      requestBody.security_cd = data.securityCode
        ? data.securityCode
        : undefined;
    }
    const client = await this.oneApiClient();
    return await client.oneUserCreate(requestBody);
  }

  async updateUser(data: UserEditForm) {
    const client = await this.oneApiClient();
    return await client.userUpdate({
      last_name: data.lastName,
      first_name: data.firstName,
      hira_last_name: data.hiraLastName,
      hira_first_name: data.hiraFirstName,
      zipcode1: data.zipcode1,
      zipcode2: data.zipcode2,
      pref: data.pref as titanApi.ReqOneUserCreatePrefEnum,
      city: data.city,
      town: data.town,
      building: data.building,
      phone1_1: data.phone1_1,
      phone1_2: data.phone1_2,
      phone1_3: data.phone1_3,
      phone2_1: data.phone2_1,
      phone2_2: data.phone2_2,
      phone2_3: data.phone2_3,
      birth_year: data.birthYear,
      birth_month: data.birthMonth,
      birth_day: data.birthDay,
      gender_typ: data.genderTyp as titanApi.ReqOneUserCreateGenderTypEnum,
      shokugyou_typ: data.shokugyouTyp as titanApi.ReqOneUserCreateShokugyouTypEnum,
      promo_mail_from_one_receive_flg: data.promoMailFromOneReceiveFlg,
      promo_mail_from_op_card_receive_flg: data.promoMailFromOpCardReceiveFlg
    });
  }

  async removeUser(opsysUnregistrationOdakyuCustomerNoList: Array<string>) {
    const req: titanApi.ReqOneUserDelete = {
      opsys_unregistration_odakyu_customer_no_list: opsysUnregistrationOdakyuCustomerNoList
    };
    const client = await this.oneApiClient();
    return await client.removeUser(req);
  }

  async createUserFromOpapp(
    data: UserSignupForm,
    digitalOpFlg: boolean,
    opappAirCard: OpappAirCard
  ) {
    const requestBody: titanApi.ReqOneUserCreateFromOpapp = {
      last_name: data.lastName,
      first_name: data.firstName,
      hira_last_name: data.hiraLastName,
      hira_first_name: data.hiraFirstName,
      zipcode1: data.zipcode1,
      zipcode2: data.zipcode2,
      pref: data.pref as titanApi.ReqOneUserCreateFromOpappPrefEnum,
      city: data.city,
      town: data.town,
      building: data.building,
      phone1_1: data.phone1_1,
      phone1_2: data.phone1_2,
      phone1_3: data.phone1_3,
      phone2_1: data.phone2_1,
      phone2_2: data.phone2_2,
      phone2_3: data.phone2_3,
      birth_year: data.birthYear,
      birth_month: data.birthMonth,
      birth_day: data.birthDay,
      gender_typ: data.genderTyp as titanApi.ReqOneUserCreateFromOpappGenderTypEnum,
      shokugyou_typ: data.shokugyouTyp as titanApi.ReqOneUserCreateFromOpappShokugyouTypEnum,
      promo_mail_from_one_receive_flg: data.promoMailFromOneReceiveFlg,
      promo_mail_from_op_card_receive_flg: data.promoMailFromOpCardReceiveFlg
    };
    // デジタルカードを発行しない場合は、小田急お客さま番号をリクエストボディに含める
    if (!digitalOpFlg) {
      requestBody.odakyu_customer_no =
        data.opCardNum1 + data.opCardNum2 + data.opCardNum3;
      requestBody.security_cd = data.securityCode
        ? data.securityCode
        : undefined;
    }
    if (opappAirCard.odakyu_customer_no && opappAirCard.security_cd) {
      requestBody.opapp_air_odakyu_customer_no =
        opappAirCard.odakyu_customer_no;
      requestBody.opapp_air_security_cd = opappAirCard.security_cd;
    }
    const client = await this.oneApiClient();
    return await client.oneUserCreateFromOpapp(requestBody);
  }
}
