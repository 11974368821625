





























































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import { UnregistrationOpCard } from '@/models/opcards/UnregistrationOpCard';

@Component({
  components: {}
})
export default class UnsubscribeDialog extends Vue {
  @Prop({ required: true })
  readonly unregistrationOpCards!: Array<UnregistrationOpCard>;

  @Prop({ required: true })
  readonly value!: boolean;

  @PropSync('buttonLoading', { type: Boolean, required: true })
  syncedButtonLoading!: boolean;

  get wrapperDialog() {
    return this.value;
  }

  set wrapperDialog(flag: boolean) {
    this.$emit('input', flag);
  }
}
