import { OpCard } from './OpCard';

export class UnregistrationOpCard extends OpCard {
  isUnregistration: boolean;
  constructor(isUnregistration: boolean, opCard: OpCard) {
    // 退会画面で不要な情報はfalse or 空文字を設定します
    super(
      opCard.odakyuCustomerNo,
      opCard.isMain,
      opCard.cardType,
      opCard.isAuthenticated,
      opCard.status,
      opCard.expire
    );
    this.isUnregistration = isUnregistration;
  }
}
